body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(300.96deg, #f76cc6 9.77%, #c471f2 99.35%);
}


.Mui-selected {
  color: #f76cc6 !important;

}

.MuiTabs-indicator {
  background-color: #f76cc6 !important;
}
